import * as React from "react";
import ctl from "@netlify/classnames-template-literals";
import { ContentWrapper } from "@topm/shared";

const WrapperContentWrapper = (props) => {
  const className: string = ctl(`
    bg-white bg-opacity-90
    ${props.className}
  `);
  return (
    <ContentWrapper {...props} className={className}>
      {props.children}
    </ContentWrapper>
  );
};

export { WrapperContentWrapper as ContentWrapper };
