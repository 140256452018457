import * as React from 'react';
import { MenuFixed } from "./menu-fixed";
import loadable from "@loadable/component";

const MenuFlyout = loadable(() => import("./menu-flyout"));

export interface MenuItem {
  href: string;
  text: string;
}
export interface MenuProps {
  data?: Array<MenuItem>;
}

const Menu: React.FC<MenuProps> = ({ data }) => {
  // for debug
  //data = [
  //  { href: "#start", text: "セミナーで得られること" },
  //  { href: "#coach", text: "講師" },
  //  { href: "#about", text: "セミナーの概要" },
  //  { href: "#schedule", text: "開催日時" },
  //  { href: "#registration", text: "お申し込み" },
  //  { href: "#organizer", text: "主催" },
  //];
  return (
    <>
      <MenuFlyout className="lg:hidden" items={data} />
      <MenuFixed className="hidden lg:block" items={data} />
    </>
  );
};

export { Menu };
