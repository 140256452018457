import React, { useRef } from "react";
import ctl from "@netlify/classnames-template-literals";
import useScrollPosition from "@react-hook/window-scroll";
import { MenuItem } from "./menu";

const ACTIVE_SCROLL_OFFSET: number = 80;
const WINDOW_WIDTH_THRESHOLD: number = 768;
const isBrowser: boolean = typeof window !== "undefined";

const getCurrentMenuHref = (scrollY: number, items: Array<MenuItem>) => {
  let result: string | null = null;
  items.forEach((item) => {
    const el = document.querySelectorAll(item.href)[0];
    if (!(el instanceof HTMLElement)) {
      //console.error("menu target was not found", item.href);
      return;
    }
    if (scrollY + ACTIVE_SCROLL_OFFSET > el.offsetTop) {
      result = item.href;
    }
  });
  return result;
};

interface ItemProps {
  data: MenuItem;
  isActive: boolean;
  index: number;
}

const Item: React.FC<ItemProps> = ({ data, isActive, index }) => {
  const aElementRef = useRef(null);
  const aClassName = ctl(`
    block
    text-sm font-bold text-lp-black
    px-2 py-1
    no-underline hover:underline
  `);
  if (aElementRef.current) {
    if (isActive) {
      aElementRef.current.classList.remove("text-lp-black");
      aElementRef.current.classList.add("text-lp-red");
    } else {
      aElementRef.current.classList.remove("text-lp-red");
      aElementRef.current.classList.add("text-lp-black");
    }
  }
  return (
    <li className="flex">
      {/* border */}
      {index !== 0 && (
        <span className="flex items-center justify-center mx-2">
          <span className="border-l border-lp-gray2 block h-3"></span>
        </span>
      )}
      <a href={data.href} className={aClassName} ref={aElementRef}>
        {data.text}
      </a>
    </li>
  );
};

interface MenuFixedProps {
  className?: string;
  items?: Array<MenuItem>;
}

const MenuFixed: React.FC<MenuFixedProps> = ({ className, items = [] }) => {
  const rootClass = ctl(`
    fixed top-0 right-0 left-0 z-50
    px-15px py-1
    bg-white bg-opacity-80 backdrop-filter backdrop-blur
    font-sans
    lp-shadow-b
  `);
  const innerClass = ctl(`
    flex justify-end
    max-w-1200px mx-auto
  `);

  const rootElementRef = useRef(null);
  const shouldHandleActiveStats = isBrowser
    ? window.innerWidth >= WINDOW_WIDTH_THRESHOLD
    : false;
  const scrollY = useScrollPosition(60); // 60FPS
  const currentActiveHref = shouldHandleActiveStats
    ? getCurrentMenuHref(scrollY, items)
    : null;

  return (
    <div className={className} ref={rootElementRef}>
      <div className={rootClass}>
        <ul className={innerClass}>
          {items.map((item, i) => (
            <Item
              key={item.href}
              data={item}
              isActive={item.href === currentActiveHref}
              index={i}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export { MenuFixed };
