import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Layout } from "@topm/shared";
import { Menu } from "../components/menu";
import { LayoutQuery } from "../graphqlTypes";

const WrappedLayout: React.FC = ({ children }) => {
  const data: LayoutQuery = useStaticQuery(graphql`
    query Layout {
      allPrismicLpTopmFive {
        edges {
          node {
            data {
              registration_url
            }
          }
        }
      }
      site {
        siteMetadata {
          menuItems {
            href
            text
          }
        }
      }
    }
  `);

  const menuItems: Array<any> = data.site.siteMetadata.menuItems;

  return (
    <Layout
      registrationUrl={
        data.allPrismicLpTopmFive.edges[0].node.data.registration_url
      }
    >
      <Menu data={menuItems} />
      {children}
    </Layout>
  );
};

export { WrappedLayout as Layout };
